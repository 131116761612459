import { createContext, useState, useContext } from 'react';
import { fetchmetadetails, submitgetcallform, submitQueryForm } from '../Config/api';
import { useAlert } from 'react-alert';

const QueryContext = createContext();

export function QueryProvider({ children }) {
    // const [data, setdata] = useState([]);
    const [pagename, setpagename] = useState("Page Name");
    const [mainpage, setmainpage] = useState('')
    const [MetaDetails, setMetaDetails] = useState({});
    // const [pageurl, setpageurl] = useState([]); 
    const alert = useAlert();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch single branch data based on URL 
    const QuerySubmitHandler = async (name,email,contact,query, pagename, pagurl) => {
        setLoading(true);
        try {
            const data = await submitQueryForm(name, email, contact,query, pagename, pagurl);
            // setBranchDetails(data.data.branch)
            // setCoursebybranch(data.data.courses)
            console.log(data)
            if (data && data.data) {
                console.log(data.data);
            }
            if (typeof window !== 'undefined') {
              if (window.location.hostname === 'localhost' || window.location.hostname === 'www.sriramsias.com') {
              document.cookie = `query=${JSON.stringify(data.data)}; path=/`;
              document.cookie = `trackname=${data.data.name}; path=/`;
              document.cookie = `trackcontact=${data.data.contact}; path=/`;
              document.cookie = `trackemail=${data.data.email}; path=/`;
              }
            }
            alert.show('Query Submit Successfully');
            // return data.data; // Return branch data from response
            return true;
        } catch (err) {
          // Extract error message from AxiosError
        const errorMessage = err.response?.data?.error || 'Failed to submit query';

            console.error("Error fetching branch by URL:", err);
            setError(errorMessage);
            alert.show(`Query Submit failed:  ${errorMessage}`);
            return false;
        } finally {
            setLoading(false);
        }
    }; 

    // Fetch single branch data based on URL 
    const getcallHandler = async (contact) => {
        console.log(contact + "CONTACT");
        setLoading(true);
        try {
            const data = await submitgetcallform(contact);
            // setBranchDetails(data.data.branch)
            // setCoursebybranch(data.data.courses)
            console.log(data)
            alert.show('Request submitted successfully');
            // return data.data; // Return branch data from response
            return true;
        } catch (err) {
          // Extract error message from AxiosError
        const errorMessage = err.response?.data?.error || 'Failed to submit query';

            console.error("Error fetching branch by URL:", err);
            setError(errorMessage);
            alert.show(`Request submitted failed:  ${errorMessage}`);
            return false;
        } finally {
            setLoading(false);
        }
    }; 

    // Fetch Meta Details based on URL 
    const MetaDetailsHandler = async (seopathname) => {
      setLoading(true);
      try {
          const data = await fetchmetadetails(seopathname);
          setMetaDetails(data.data[0]); // Set Meta Details data from response
      } catch (err) {
        // Extract error message from AxiosError
      const errorMessage = err.response?.data?.error || 'Failed to Fetch Meta Details';

          console.error("Error fetching Meta Details by URL:", err);
          setError(errorMessage);
      } finally {
          setLoading(false);
      }
  }; 


    const getPagename = (url) =>{
setpagename(url)
    }

    const breadcrumbhandler =(name) =>{
      setmainpage(name)
    }
  
    return (
      <QueryContext.Provider value={{pagename, MetaDetails, mainpage, loading, error, QuerySubmitHandler, getPagename, breadcrumbhandler, getcallHandler, MetaDetailsHandler }}>
        {children}
      </QueryContext.Provider>
    );
  }
  
  export function useQueryContext() {
    return useContext(QueryContext);
  }