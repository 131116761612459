import React, { useEffect } from "react"; // Import the useEffect and useState hooks from React
import SEO from "../../SeoWidget/SEO/SEO"; // Import the SEO component
import { useTestimonialContext } from "../../../Context/Testimonialapi";  // Import the useTestimonialContext
import { useAboutContext } from "../../../Context/Aboutapi";  // Import the useAboutContext
import UseAnalytics from "../../GoogleAnalytics/UseAnalytics";   // Import the UseAnalytics component
import { useQueryContext } from "../../../Context/QueryManagement"; // Import the useQueryContext
import { useTrackingManagementContext } from "../../../Context/TrackingManagement"; // Import the useTrackingManagementContext

function TestimonialDetailsPage() {  

    const {MetaDetails, MetaDetailsHandler} = useQueryContext(); // Destructure the getPagename, MetaDetails, MetaDetailsHandler, and pagename from the useQueryContext

    const { aboutsectionDetails, fetchAboutSectionByHomeHandler } =
    useAboutContext();  // Destructure the aboutsectionDetails, loading, error, and fetchAboutSectionByHomeHandler from the useAboutContext
   
    const { Testimonial, fetchTestimonialHandler } =
    useTestimonialContext();  // Destructure the Testimonial and fetchTestimonialHandler from the useTestimonialContext

          const { handleUrlChange } = useTrackingManagementContext(); // Destructure the trackingid and handleButtonClick functions

    useEffect(()=>{
        fetchAboutSectionByHomeHandler()
        fetchTestimonialHandler()
    },[]) // Fetch the about section and testimonial data

      useEffect(()=>{ 
          MetaDetailsHandler("testimonials")
       },[])  // Set the MetaDetailsHandler to "testimonials"

                useEffect(()=>{
                  setTimeout(() => {
                  handleUrlChange();
                }, 2000); // Set a delay of 2 seconds
                },[]) // Trigger URL change handling with a delay

       const metatitle = MetaDetails?.metatitle || "";  // Set the metatitle to the MetaDetails metatitle or an empty string
       const metadescription = MetaDetails?.metadescription || "";  // Set the metadescription to the MetaDetails metadescription or an empty string

  return (
    <>
    <UseAnalytics />  {/* Use the Google Analytics component */}
    <SEO seot1={metatitle} seot2={metadescription} /> {/* Use the SEO component */}
    <div className="container" style={{paddingTop:"20px"}}> 
<div className="testimonial-heading-area text-center">  
                            <div className="section-title section-left text-center">
                                <span className="pre-title custom-font-family">Testimonials</span>
                                <h2 className="title custom-font-family">What Our Students Have To Say</h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p className="custom-font-family" dangerouslySetInnerHTML={{ __html: aboutsectionDetails.testimonialdescription }} />
                            </div>
                        </div>
                        </div>  
      <section className="edu-section-gap course-details-area" style={{padding:"0px 0px 120px"}}> 
        <div className="container"> 
          <div className="row"> 
              {Testimonial.map((Test, index)=>(<div className="col-lg-4 p-3"> 
              <div className="testimonial-grid">
                                    <div className="thumbnail">
                                        <img src={Test.pic} style={{width: "80px"}} alt="Testimonial"/>
                                        <span className="qoute-icon"><i className="icon-26"></i></span>
                                    </div>
                                    <div className="content">
                                        <p>{Test.testimonials}</p>
                                        <h6 className="title">{Test.name}</h6>
                                        <span className="subtitle fs-4">Student</span>
                                        <div className="course-rating mt-2">
                                            <div className="rating">
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            <i className="icon-23 fs-5 text-warning"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
              </div>))}
            </div>
          </div>
      </section>

    </>
  );
}

export default TestimonialDetailsPage;
