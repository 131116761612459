import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import CustomEnquery from '../Form/CustomEnqueryPop';
import { useWebdetailsContext } from '../../Context/WebDetails';
import { Link } from 'react-router-dom';

function SaleModal({open, setOpen, handleClose}) {
  const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
  useWebdetailsContext();

  useEffect(() => {
    fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
  }, []); 

  // Handlers to open and close the modal
  


  // Styles for the modal's content
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    width: '96%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 0,
    // animation: "pulse 1s ease-out forwards",
  };

  const Header1 = Webdetails?.header2 || "Header Here";
  const title1 = Webdetails?.title2 || "Title Here";
  const title2 = Webdetails?.subtitle2 || "subtitle Here";
  const link = Webdetails?.imagelink || "subtitle Here";
  const image = Webdetails?.himage || "subtitle Here";

  return (
    <div>
      {/* The Modal component */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style} className="border-0">
          {/* <Typography id="modal-description" sx={{ mt: 2 }}> */}
            <div className='bg-danger d-flex justify-content-center align-items-center' style={{width:"30px", height: "30px"}}  id='closeIcon'>
            <span className='text-white' style={{marginTop: "-6px"}} onClick={()=>handleClose()}>&times;</span>
            </div>
            <Link to={`${link}`}>
        {/* <span className="text-danger  fs-6  p-2 mx-2 fs-4" style={{cursor: "pointer"}} onClick={()=>handleClose()}><i className='icon-73'></i></span> */}
            <img src={image} onClick={()=>handleClose()} style={{width: "100%", backgroundSize: "cover"}} alt=''/>
            </Link>
          {/* </Typography> */}
          {/* Close button inside the modal */}
          
        </Box>
      </Modal>
    </div>
  );
}

export default SaleModal;
