import React, { useEffect, useRef } from "react";
import queryimg from "../../assets/images/query-img.png";
import { useQueryContext } from "../../Context/QueryManagement";
import { Button } from "@mui/material";
import { useValidation } from "../../Context/VlidationForm";
import { useNavigate } from "react-router-dom";

function CustomEnqueryPop({handleClose, head, title, subtile}) {


    const navigate = useNavigate();
   // Access QuerySubmitHandler from the context
   const {pagename, QuerySubmitHandler } = useQueryContext();
   const { formValues, formErrors, setFormValues, setFormErrors, validateField, handleInputChange } = useValidation();

     // Use a reference to the form to reset it after submission
  const formRef = useRef(null);

  const handleForm = async (e) => {
    e.preventDefault();
    const errors = {};

    // Validate all fields
    Object.keys(formValues).forEach((field) => {
      errors[field] = validateField(field, formValues[field]);
    });

    setFormErrors(errors);

    // If no errors, submit the form
    if (Object.values(errors).every((error) => error === "")) {
      const success = await QuerySubmitHandler(
        formValues.name,
        formValues.contact,
        formValues.email,
        formValues.query,
        pagename,
        window.location.href
      );

      if (success) {
        formRef.current.reset();
        navigate('/thank-you');
        setFormValues({ name: "", email: "", contact: "", query: "" }); // Reset form values
        handleClose();
      }
    }
  };

  return (
    <>
    
    <div className="col-lg-12 mt-4">
        <form name="form1" ref={formRef} className="rnt-contact-form rwt-dynamic-form" action="" onSubmit={handleForm} method="post">
        {["name","contact","email"].map((field) => (
  <div className="form-floating my-2" key={field}>
      <input
        type={field === "contact" ? "number" : field === "email" ? "email" : "text"}
        className={`form-control py-4 pb-3 ${formErrors[field] ? "border-danger" : ""}`}
        name={field}
        style={{ fontSize: "14px", border: "1px solid #ccc" }}
        placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
        onChange={handleInputChange} 
        onBlur={(e) => setFormErrors({ ...formErrors, [field]: validateField(field, e.target.value) })}
      />
    <label className="fs-5">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
    {formErrors[field] && <small className="text-danger fs-5">{formErrors[field]}</small>}
  </div>
))}
          <span className="text-danger fs-5">* {subtile}</span>
          {/* <div className="form-check px-0">
  <input className="form-check-input" type="checkbox" name="checkbox" id="notshow"/>
  <label className="form-check-label fs-5" for="notshow">
    Don't Show Again
  </label>
</div> */}
                                              <div className="col-12 d-flex align-items-center justify-content-end">
                                              {/* <button color="btn-outlin-danger border-0 rounded-pill"> */}
  {/* <span className="bg-danger text-white fs-6 px-4 py-1 mx-2" style={{cursor: "pointer"}} onClick={()=>handleClose()}>CLOSE</span> */}
{/* </button> */}
<a className="rn-btn btn-danger btn-small me-3 p-3  py-2 text-white fs-6 submit-btn" style={{cursor: "pointer", borderRadius: "2px"}} onClick={()=>handleClose()}>CLOSE</a>
<button className="rn-btn btn-dark p-3 py-2  fs-6 submit-btn" name="submit" type="submit">Schedule a Call</button>
                                              {/* <Button className="px-4 py-1 fs-6" name="submit" type="submit" variant="outlined" sx={{ mt: 0 }}>
            Submit
          </Button> */}
                                    </div>
        </form>
      {/* </div> */}
                            {/* <ul className="shape-group">
                                <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape"/></li>
                                <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape"/></li>
                            </ul> */}
                        {/* </div> */}
                    </div>
    </>
  );
}

export default CustomEnqueryPop;
