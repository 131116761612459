import React, { Suspense, useEffect } from "react";
// import { messaging } from "./firebase";
// import { getToken } from "firebase/messaging";

/*
**********
All Css and ScSS File are imported here.
**********
*/
import "./App.css";
import "./styles/marquee.scss";
import "./styles/upload.scss";
import "./styles/herocard.scss";
import "./styles/customdropdown.scss";
import "./styles/textanimation.scss";
import "./styles/animatedbackground.scss";
import "./styles/calendar.scss";
import "./styles/registration.scss";
import "./styles/books.scss";
import "./styles/common.scss";
import "./styles/custombutton.scss";
import "./styles/querybutton.scss";
import "./styles/error.scss";
import "./styles/preloader.scss";
import "./styles/responisve.scss";
import "./styles/dropdown.scss";

/*
************
Imported React Router DOM for Routing
************
*/
import { BrowserRouter, Routes, Route } from "react-router-dom";

/*
***********
Imported Context API to manage data in the application.
***********
*/
import { ButtonDetailsProvider } from "./Context/Pagebuttonmanagement";
import { BranchProvider } from "./Context/Branchapi";
import { BackgroundProvider } from "./Context/BackgroundChange";
import { BlogsProvider } from "./Context/Blogsapi";
import { CourseProvider } from "./Context/Courseapi";
import { ArticleProvider } from "./Context/Articleapi";
import { DateProvider } from "./Context/Dateapi";
import { WebdetailsProvider } from "./Context/WebDetails";
import { NewsheadlineProvider } from "./Context/NewsHeadline";
import { SeoProvider } from "./Context/SEOManagement";
import { HelmetProvider } from "react-helmet-async";
import { ContentProvider } from "./Context/ContentManagement";
import { ToggleProvider } from "./Context/Togglemanagement";
import { ScrollingManagementProvider } from "./Context/ScrollingEventManagement";
import { TestProvider } from "./Context/Testpi";
import { QueryProvider } from "./Context/QueryManagement";
import { AboutProvider } from "./Context/Aboutapi";
import { FooterProvider } from "./Context/footerapi";
import { PageProvider } from "./Context/Pageapi";
import { HomeProvider } from "./Context/Homeapi";
import { InterviewProvider } from "./Context/Interviewapi";
import { BranchwiseidProvider } from "./Context/BranchwiseCourse";
import { ExplorationProvider } from "./Context/Explorationapi";
import { PackageProvider } from "./Context/packagemanagement";
import { ModalProvider } from "./Context/Modalmanagement";
import { TestimonialProvider } from "./Context/Testimonialapi";
import { ValidateUrlProvider } from "./Context/Validateurl";
import { NotFoundProvider } from "./Context/NotFound";
import { VideoProvider } from "./Context/Videoapi";
import { MagazineProvider } from "./Context/Magazineapi";
import { McqProvider } from "./Context/Mcqapi";
import { GeneralEssayProvider } from "./Context/GeneralEssayapi";
import { CurrentaffairsProvider } from "./Context/Currentaffairsapi";
import { SaleModalProvider } from "./Context/SaleModalmanagement";
import { BooksProvider } from "./Context/Booksapi";
import { TrackingManagementProvider } from "./Context/TrackingManagement";
import { ValidationProvider } from "./Context/VlidationForm";

/*
************
Imported Components Here
************
*/ 
import NotFoundPage from "./Component/Pages/NotFoundPage/NotFoundPage";
import Preloader from "./Component/Preloader/Preloader1";
import MobileFooterNavbar from "./Component/NavbarDropdown/MobileFooterNavbar/MobileFooterNavbar";
import TestimonialDetailsPage from "./Component/Pages/TestimonialDetailsPage/TestimonialDetailsPage";
import Testdetails from "./Component/Testdetails/Testdetails";
import HomePage from "./Component/Pages/HomePage/HomePage";
import Coming_SoonPage from "./Component/Pages/Coming SoonPage/Coming_SoonPage";
import BranchPage from "./Component/Pages/BranchPage/BranchPage";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import ScrollToTopSVG from "./Component/ScrollToTop/ScrollToTop";
import useEduJs from "./Hooks/useEduJs1";
import ThankYouPage from "./Component/Pages/ThankYouPage/ThankYouPage";
import AppscMagazinePage from "./Component/Pages/AppscMagazinePage/AppscMagazinePage";
import { Helmet } from "react-helmet";
import Article from "./Component/Pages/ArticleDetailsPage/Article";
/*
***********
React Lazy Loading is used to load components on demand.
***********
*/
const BranchDetailPage = React.lazy(() => import("./Component/Pages/BranchDetailPage/BranchDetailPage"));
const CoursedetailsPage = React.lazy(() =>
  import("./Component/Pages/CoursedetailsPage/CoursedetailsPage")
);
const BlogsPage = React.lazy(() => import("./Component/Pages/BlogsPage/BlogsPage"));
const InterviewPage = React.lazy(() => import("./Component/Pages/InterviewPage/InterviewPage"));
const ArticlesPage = React.lazy(() => import("./Component/Pages/ArticlesPage/ArticlesPage"));
const OldArticlesPage = React.lazy(() => import("./Component/Pages/OldArticlesPage/OldArticlesPage"));
const BlogDetailsPage = React.lazy(() =>
  import("./Component/Pages/BlogDetailsPage/BlogDetailsPage")
);
const ArticleDetailsPage = React.lazy(() =>
  import("./Component/Pages/ArticleDetailsPage/ArticlesDetailsPage")
);
const AboutPage = React.lazy(() => import("./Component/Pages/AboutPage/AboutPage"));
const ReferalPolicyPage = React.lazy(() =>
  import("./Component/Pages/ReferalPolicyPage/ReferalPolicyPage")
);
const PageComponent1 = React.lazy(() =>
  import("./Component/PageComponent/PageComponent1")
);
const CampanyPolicyPage = React.lazy(() =>
  import("./Component/Pages/CampanyPolicyPage/CampanyPolicyPage")
);
const Exploration = React.lazy(() =>
  import("./Component/Exploration/Exploration")
);
const ExploredetailsPage = React.lazy(() =>
  import("./Component/Pages/ExploreDetailsPage/ExploreDetailsPage")
);
const RegistrationPage = React.lazy(() =>
  import("./Component/Pages/RegistrationPage/RegistrationPage") 
);
const Contactpage = React.lazy(() =>
  import("./Component/Pages/Contactpage/Contactpage")
);
const Videos = React.lazy(() => import("./Component/Videos/Videos"));
const DisplayCoursePage = React.lazy(() =>
  import("./Component/Pages/DisplayCoursePage/DisplayCoursePage")
);
const DisplayTestseriesPage = React.lazy(() =>
  import("./Component/Pages/DisplayTestseriesPage/DisplayTestseriesPage")
);

const MagazinePage = React.lazy(() =>
  import("./Component/Pages/Magazinpage/Magazinepage")
);
const McqPage = React.lazy(() => import("./Component/Pages/McqPage/McqPage"));
const GeneralEssay = React.lazy(() =>
  import("./Component/GeneralEssay/GeneralEssay")
);
const CurrentAffairsPage = React.lazy(() =>
  import("./Component/Pages/CurrentAffairsPage/CurrentAffairsPage")
);
const BooksPage = React.lazy(() => import("./Component/Pages/BooksPage/BooksPage"));
const FacultyPage = React.lazy(() =>
  import("./Component/Pages/FacutlyPage/FacultyPage")
);
const FaqsPage = React.lazy(() => import("./Component/Pages/FaqPage/FaqsPage"));

function App() { 
  useEduJs();

  useEffect(() => {
    // Create a noscript element and append it to the body
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5XQF97QC" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    document.body.appendChild(noscript);

    // Cleanup function to remove noscript on unmount
    return () => {
      document.body.removeChild(noscript);
    };
  }, []);
 
  //  async function requestPermission(params) {
  //   const permission = await Notification.requestPermission();
  //   if(permission === 'granted'){
  //     // Generate Token
  //     const token = await getToken(messaging, {vapidKey: 'BIWamgBqbgzPuYAU-lFw70wi_sN_QAKp7RYcTCwQBnBm3ccamGZpGS09urTN2-61YHCgfzKYj89kmRPR6Jh2GFQ'})
  //     console.log('Token Gen',token)
  //   } else if(permission === 'denied'){
  //     // Show Notification Permission Denied
  //     alert('You have denied the Notification Permission');

  //   }
  //  }


  // useEffect(() => {
  //   // Req user for notification Permission
  //    requestPermission()
  // }, []);
  return ( 
    <>

      <HelmetProvider> 
      
       {/* Google Tag Manager script */}
       <Helmet>
          <script type="text/javascript">
            {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[]; 
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5XQF97QC');
          `}
          </script>
        </Helmet>

        <TrackingManagementProvider>
        <QueryProvider>
          <BranchwiseidProvider>
            <SeoProvider>
              <WebdetailsProvider>
                <ScrollingManagementProvider>
                  <BackgroundProvider>
                    <BranchProvider>
                      <CourseProvider>
                        <BooksProvider>
                        <ValidationProvider>
                          
                            <TestimonialProvider>
                              <AboutProvider>
                                <ValidateUrlProvider>
                                  <BrowserRouter>
                                    <SaleModalProvider>
                                      <NotFoundProvider>
                                      <ModalProvider>
                                        <VideoProvider>
                                          <NewsheadlineProvider>
                                            <ToggleProvider>
                                              <Header />
                                            </ToggleProvider>
                                          </NewsheadlineProvider>
                                          <Routes>
                                            {/* 
                                            ******************
                                            Home page route (/)
                                            ******************
                                            */}
                                            <Route
                                              path="/"
                                              element={
                                                <>
                                                  <HomeProvider>
                                                    <HomePage />
                                                  </HomeProvider>
                                                </>
                                              }
                                            />

                                            {/* 
                                            ******************
                                            Faculty page route (/faculty)
                                            ******************
                                            */}
                                            <Route
                                              path="/faculty"
                                              element={
                                                <>
                                                  <Suspense
                                                    fallback={
                                                      <>
                                                        <Preloader />
                                                      </>
                                                    }
                                                  >
                                                    <FacultyPage />
                                                  </Suspense>
                                                </>
                                              }
                                            />

                                            {/* 
                                            ******************
                                            Frequently Asked Questions page route (/faq)
                                            ******************
                                            */}
                                            <Route
                                              path="/faq"
                                              element={
                                                <>
                                                  <Suspense
                                                    fallback={
                                                      <>
                                                        <Preloader />
                                                      </>
                                                    }
                                                  >
                                                    <FaqsPage />
                                                  </Suspense>
                                                </>
                                              }
                                            />
                                            
                                                                                        {/* 
                                            ******************
                                            Testimonial Details page route (/testimonial-details)
                                            ******************
                                            */}
                                            <Route
                                              path="/testimonial-details"
                                              element={<TestimonialDetailsPage />}
                                            />

                                                                                        {/* 
                                            ******************
                                            Branches page route (/branches)
                                            ******************
                                            */}
                                            <Route
                                              path="/branches"
                                              element={<BranchPage />}
                                            />

                                                                                        {/* 
                                            ******************
                                            dynamic About Details page route (/about/:abouturl)
                                            ******************
                                            */}
                                            <Route
                                              path="/about/:abouturl"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <AboutProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <AboutPage />
                                                      </Suspense>
                                                    </AboutProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                                                                                        {/* 
                                            ******************
                                            dynamic Upsc Details page route (/privacy-policy)
                                            ******************
                                            */}
                                            <Route
                                              path="/privacy-policy"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <PageProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <CampanyPolicyPage />
                                                      </Suspense>
                                                    </PageProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            /> 
                                                                                        {/* 
                                            ******************
                                            dynamic Upsc Details page route (/refund-policy)
                                            ******************
                                            */}
                                            <Route
                                              path="/refund-policy"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <PageProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <CampanyPolicyPage />
                                                      </Suspense>
                                                    </PageProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            /> 
                                                                                        {/* 
                                            ******************
                                            dynamic Upsc Details page route (/terms-and-conditions)
                                            ******************
                                            */}
                                            <Route
                                              path="/terms-and-conditions"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <PageProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <CampanyPolicyPage />
                                                      </Suspense>
                                                    </PageProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            /> 

                                                                                        {/* 
                                            ******************
                                            dynamic Upsc Details page route (/upsc/:pageurl)
                                            ******************
                                            */}
                                            <Route
                                              path="/upsc/:pageurl"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <PageProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <PageComponent1 />
                                                      </Suspense>
                                                    </PageProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            /> 

                                                                                     {/* 
                                            ******************
                                            Static Upsc Details page route (/upsc/referal-policy)
                                            ******************
                                            */}
                                            <Route
                                              path="/upsc/referal-policy"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <PageProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <ReferalPolicyPage />
                                                      </Suspense>
                                                    </PageProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                                                                                  {/* 
                                            ******************
                                            dynamic blog Details page route (/blog/:blogurl)
                                            ******************
                                            */}
                                            <Route
                                              path="/blog/:blogurl"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <BlogsProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <BlogDetailsPage />
                                                      </Suspense>
                                                    </BlogsProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />  
                                                                               {/* 
                                            ******************
                                            dynamic explore Details page route (/explore/:blogurl)
                                            ******************
                                            */}
                                            <Route
                                              path="/explore/:exploreurl"
                                              element={
                                                <>
                                                  <BackgroundProvider> 
                                                    <ExplorationProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <ExploredetailsPage />
                                                      </Suspense>
                                                    </ExplorationProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                                                                            {/* 
                                            ******************
                                            dynamic upsc-daily-current-affairs Details page route (/upsc-daily-current-affairs/:articleurl)
                                            ******************
                                            */}
                                            <Route
                                              exact
                                              path="/upsc-daily-current-affairs/:articleurl/"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <ArticleProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <ArticleDetailsPage />
                                                      </Suspense>
                                                    </ArticleProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />
                                            <Route
                                              exact
                                              path="/article/:articleurl/"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <ArticleProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <Article />
                                                      </Suspense>
                                                    </ArticleProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                                                                         {/* 
                                            ******************
                                            upsc-daily-current-affairs page route (/upsc-daily-current-affairs)
                                            ******************
                                            */}
                                            <Route
                                              path="/upsc-daily-current-affair"
                                              element={
                                                <>
                                                  <DateProvider>
                                                    <BackgroundProvider>
                                                      <ArticleProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <ArticlesPage />
                                                        </Suspense>
                                                      </ArticleProvider>
                                                    </BackgroundProvider>
                                                  </DateProvider>
                                                </>
                                              }
                                            />

                                                                         {/* 
                                            ******************
                                            upsc-daily-current-affairs page route (/upsc-daily-current-affairs)
                                            ******************
                                            */}
                                            <Route
                                              path="/articles"
                                              element={
                                                <>
                                                  <DateProvider>
                                                    <BackgroundProvider>
                                                      <ArticleProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <OldArticlesPage />
                                                        </Suspense>
                                                      </ArticleProvider>
                                                    </BackgroundProvider>
                                                  </DateProvider>
                                                </>
                                              }
                                            />

                                                                         {/* 
                                            ******************
                                            blog page route (/blogs)
                                            ******************
                                            */}
                                            <Route
                                              path="/blogs"
                                              element={
                                                <>
                                                  <DateProvider>
                                                    <BackgroundProvider>
                                                      <BlogsProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <BlogsPage />
                                                        </Suspense>
                                                      </BlogsProvider>
                                                    </BackgroundProvider>
                                                  </DateProvider>
                                                </>
                                              }
                                            />

                                                                      {/* 
                                            ******************
                                            interview-guidance-program page route (/interview-guidance-program)
                                            ******************
                                            */}
                                            <Route
                                              path="/interview-guidance-program"
                                              element={
                                                <>
                                                  <DateProvider>
                                                    <BackgroundProvider>
                                                      <InterviewProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <InterviewPage />
                                                        </Suspense>
                                                      </InterviewProvider>
                                                    </BackgroundProvider>
                                                  </DateProvider>
                                                </>
                                              }
                                            />

                                                                   {/* 
                                            ******************
                                            exploration page route (/exploration)
                                            ******************
                                            */}
                                            <Route
                                              path="/exploration"
                                              element={
                                                <>
                                                  <DateProvider>
                                                    <BackgroundProvider>
                                                      <ExplorationProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <Exploration />
                                                        </Suspense>
                                                      </ExplorationProvider>
                                                    </BackgroundProvider>
                                                  </DateProvider>
                                                </>
                                              }
                                            />

                                                                {/* 
                                            ******************
                                            videos page route (/videos)
                                            ******************
                                            */}
                                            <Route
                                              path="/videos"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <DateProvider>
                                                      <ButtonDetailsProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <Videos />
                                                        </Suspense>
                                                      </ButtonDetailsProvider>
                                                    </DateProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                    {/* 
                                            ******************
                                            books page route (/books)
                                            ******************
                                            */}
                                            <Route
                                              path="/books"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <BooksProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <BooksPage />
                                                      </Suspense>
                                                    </BooksProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />
                                            <Route
                                              path="/appsc-magazine"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <BooksProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <AppscMagazinePage />
                                                        {/* <Books /> */}
                                                      </Suspense>
                                                    </BooksProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                    {/* 
                                            ******************
                                            magazine page route (/magazine)
                                            ******************
                                            */}
                                            <Route
                                              path="/magazine"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <MagazineProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <MagazinePage />
                                                      </Suspense>
                                                    </MagazineProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                                                                {/* 
                                            ******************
                                            Multiple Choice Question page route (/mcq)
                                            ******************
                                            */}
                                            <Route
                                              path="/mcq"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <McqProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <McqPage />
                                                      </Suspense>
                                                    </McqProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                 {/* 
                                            ******************
                                            General Essay page route (/general-essay)
                                            ******************
                                            */}
                                            <Route
                                              path="/general-essay"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <GeneralEssayProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <GeneralEssay />
                                                      </Suspense>
                                                    </GeneralEssayProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                                                             {/* 
                                            ******************
                                            Current Affairs page route (/current-affairs)
                                            ******************
                                            */}
                                            <Route
                                              path="/current-affairs"
                                              element={
                                                <>
                                                  <BackgroundProvider>
                                                    <CurrentaffairsProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <CurrentAffairsPage />
                                                      </Suspense>
                                                    </CurrentaffairsProvider>
                                                  </BackgroundProvider>
                                                </>
                                              }
                                            />

                                                          {/* 
                                            ******************
                                            Course page route (/course)
                                            ******************
                                            */}
                                            <Route
                                              path="/course"
                                              element={
                                                <ButtonDetailsProvider>
                                                  <Suspense
                                                    fallback={
                                                      <>
                                                        <Preloader />
                                                      </>
                                                    }
                                                  >
                                                    <DisplayCoursePage />
                                                  </Suspense>
                                                </ButtonDetailsProvider>
                                              }
                                            />

                                            
                                                                        {/* testing inComplete /videos, /general-essay and testing complete /course **********************************************************
                                            *****************************************************
                                            ********************************
                                            *******************
                                            *************
                                            *******
                                            **** */}

                                                          {/* 
                                            ******************
                                            testseries page route (/testseries)
                                            ******************
                                            */}
                                            <Route
                                              path="/testseries"
                                              element={
                                                <ButtonDetailsProvider>
                                                  <Suspense
                                                    fallback={
                                                      <>
                                                        <Preloader />
                                                      </>
                                                    }
                                                  >
                                                    <DisplayTestseriesPage />
                                                  </Suspense>
                                                </ButtonDetailsProvider>
                                              }
                                            />

                                                          {/* 
                                            ******************
                                            Course details page route (/Course-details/:courseurl)
                                            ******************
                                            */}
                                            <Route
                                              path="/Course-details/:courseurl"
                                              element={
                                                <>
                                                  <ContentProvider>
                                                    <ButtonDetailsProvider>
                                                      <BackgroundProvider>
                                                        <CourseProvider>
                                                          <Suspense
                                                            fallback={
                                                              <>
                                                                <Preloader />
                                                              </>
                                                            }
                                                          >
                                                            <CoursedetailsPage />
                                                          </Suspense>
                                                        </CourseProvider>
                                                      </BackgroundProvider>
                                                    </ButtonDetailsProvider>
                                                  </ContentProvider>
                                                </>
                                              }
                                            />

                                                       {/* 
                                            ******************
                                            test series details page route (/test-series/:testurl)
                                            ******************
                                            */}
                                            <Route
                                              path="/test-series/:testurl"
                                              element={
                                                <>
                                                  <ContentProvider>
                                                    <ButtonDetailsProvider>
                                                      <BackgroundProvider>
                                                        <TestProvider>
                                                          <Suspense
                                                            fallback={
                                                              <>
                                                                <Preloader />
                                                              </>
                                                            }
                                                          >
                                                            <Testdetails />
                                                          </Suspense>
                                                        </TestProvider>
                                                      </BackgroundProvider>
                                                    </ButtonDetailsProvider>
                                                  </ContentProvider>
                                                </>
                                              }
                                            />

                                                    {/* 
                                            ******************
                                            branch Details page route (/branch/:branchUrl)
                                            ******************
                                            */}
                                            <Route
                                              path="/old-rajinder-nagar"
                                              element={
                                                <>
                                                  <ButtonDetailsProvider>
                                                    <BackgroundProvider>
                                                      <BranchProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <BranchDetailPage />
                                                        </Suspense>
                                                      </BranchProvider>
                                                    </BackgroundProvider>
                                                  </ButtonDetailsProvider>
                                                </>
                                              }
                                            />
                                                    {/* 
                                            ******************
                                            branch Details page route (/branch/:branchUrl)
                                            ******************
                                            */}
                                            <Route
                                              path="/mukherjee-nagar"
                                              element={
                                                <>
                                                  <ButtonDetailsProvider>
                                                    <BackgroundProvider>
                                                      <BranchProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <BranchDetailPage />
                                                        </Suspense>
                                                      </BranchProvider>
                                                    </BackgroundProvider>
                                                  </ButtonDetailsProvider>
                                                </>
                                              }
                                            />
                                                    {/* 
                                            ******************
                                            branch Details page route (/branch/:branchUrl)
                                            ******************
                                            */}
                                            <Route
                                              path="/pune"
                                              element={
                                                <>
                                                  <ButtonDetailsProvider>
                                                    <BackgroundProvider>
                                                      <BranchProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <BranchDetailPage />
                                                        </Suspense>
                                                      </BranchProvider>
                                                    </BackgroundProvider>
                                                  </ButtonDetailsProvider>
                                                </>
                                              }
                                            />
                                                    {/* 
                                            ******************
                                            branch Details page route (/branch/:branchUrl)
                                            ******************
                                            */}
                                            <Route
                                              path="/hyderabad"
                                              element={
                                                <>
                                                  <ButtonDetailsProvider>
                                                    <BackgroundProvider>
                                                      <BranchProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <BranchDetailPage />
                                                        </Suspense>
                                                      </BranchProvider>
                                                    </BackgroundProvider>
                                                  </ButtonDetailsProvider>
                                                </>
                                              }
                                            />

                                                 {/* 
                                            ******************
                                            Contact Us page route (/contact-us)
                                            ******************
                                            */}
                                            <Route
                                              path="/contact-us"
                                              element={
                                                <>
                                                  <ButtonDetailsProvider>
                                                    <BackgroundProvider>
                                                      <BranchProvider>
                                                        <Suspense
                                                          fallback={
                                                            <>
                                                              <Preloader />
                                                            </>
                                                          }
                                                        >
                                                          <Contactpage />
                                                        </Suspense>
                                                      </BranchProvider>
                                                    </BackgroundProvider>
                                                  </ButtonDetailsProvider>
                                                </>
                                              }
                                            />

                                            {/* 
                                            ******************
                                            Error page route (/Error)
                                            ******************
                                            */}
                                            <Route
                                              path="/error"
                                              element={
                                                <>
                                                  <NotFoundPage />
                                                </>
                                              }
                                            />
                                            <Route
                                              path="/thank-you"
                                              element={
                                                <>
                                                  <ThankYouPage />
                                                </>
                                              }
                                            />

                                                                                        {/* 
                                            ******************
                                            Comning soon page route (/*)
                                            ******************
                                            */}
                                            <Route
                                              path="/*"
                                              element={<Coming_SoonPage />}
                                            />

                                                                                        {/* 
                                            ******************
                                            Registration page route (/registration)
                                            ******************
                                            */}
                                            <Route
                                              path="/registration"
                                              element={
                                                <>
                                                  <PackageProvider>
                                                    <ButtonDetailsProvider>
                                                      <Suspense
                                                        fallback={
                                                          <>
                                                            <Preloader />
                                                          </>
                                                        }
                                                      >
                                                        <RegistrationPage />
                                                      </Suspense>
                                                    </ButtonDetailsProvider>
                                                  </PackageProvider>
                                                </>
                                              }
                                            />
                                          </Routes>

                                          <FooterProvider>
                                            <Footer />
                                          </FooterProvider>
                                          <ScrollToTopSVG />
                                      
                                          <MobileFooterNavbar />
                                        </VideoProvider>
                                        </ModalProvider>
                                      </NotFoundProvider>
                                    </SaleModalProvider>
                                  </BrowserRouter>
                                </ValidateUrlProvider>
                              </AboutProvider>
                            </TestimonialProvider>
                          </ValidationProvider>
                        </BooksProvider>
                      </CourseProvider>
                    </BranchProvider>
                  </BackgroundProvider>
                </ScrollingManagementProvider>
              </WebdetailsProvider>
            </SeoProvider>
          </BranchwiseidProvider>
        </QueryProvider>
        </TrackingManagementProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
