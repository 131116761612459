import React, { useEffect, useRef } from "react"; 
import { useQueryContext } from "../../Context/QueryManagement"; // Context for managing query data
import { useWebdetailsContext } from "../../Context/WebDetails"; // Context for web details
import { useTrackingManagementContext } from "../../Context/TrackingManagement"; // Context for tracking button clicks
import { useValidation } from "../../Context/VlidationForm"; // Context for form validation
import { useNavigate } from "react-router-dom";

function CustomEnquery() {
  const navigate = useNavigate();
  // Destructure context values for query management, tracking, web details, and validation
  const { pagename, QuerySubmitHandler } = useQueryContext();
  const { trackingid, handleButtonClick } = useTrackingManagementContext();
  const { Webdetails, fetchWebdetailsByUrlHandler } = useWebdetailsContext();
  const {
    formValues,
    formErrors,
    setFormValues,
    setFormErrors,
    validateField,
    handleInputChange,
  } = useValidation();

  // Fetch web details when the component mounts
  useEffect(() => {
    fetchWebdetailsByUrlHandler(); // Fetch web details on component mount
  }, []); // Empty dependency array ensures this only runs once on mount

  const formRef = useRef(null); // Create a reference for the form

  const handleForm = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const errors = {}; // Object to collect validation errors

    // Validate all fields
    Object.keys(formValues).forEach((field) => {
      errors[field] = validateField(field, formValues[field]);
    });

    setFormErrors(errors); // Update form errors state

    // If no errors, submit the form
    if (Object.values(errors).every((error) => error === "")) {
      const success = await QuerySubmitHandler(
        formValues.name,
        formValues.contact,
        formValues.email,
        formValues.query,
        pagename,
        window.location.href
      );

      // Reset form if submission is successful
      if (success) {
        formRef.current.reset(); // Reset the form fields
        navigate('/thank-you');
        setFormValues({ name: "", email: "", contact: "", query: "" }); // Clear the form values
      }
    }
  };

   // Set default values for header and title if not available
  const Header1 = Webdetails?.header1 || "Header Here";
  const title1 = Webdetails?.title1 || "Title Here";

  return (
    <div className="col-lg-12 mt-4">
      <div
        className="contact-form form-style-2 px-0 pt-0 py-5 animated-gradient-background1"
        style={{
          border: "1px solid #ccc",
          background: "linear-gradient(345deg, #ECECFF,white 60%)",
        }}
      >
        <div className="section-title" style={{ background: "#301C6F" }}>
          <h4 className="title text-center text-white w-100 py-4 custom-font-family">
            {Header1}
          </h4>
          <p
            className="px-1 text-white text-center custom-font-family"
            style={{ paddingBottom: "7px" }}
          >
            {title1}
          </p>
        </div>
        <div
          className="form-wrapper rnt-contact-form rwt-dynamic-form px-5"
          id="signin"
        >
          <form
            ref={formRef}
            className="needs-validation custom-font-family"
            onSubmit={handleForm}
          >
            {["name", "email", "contact", "query"].map((field) => (
              <div
                className="form-floating my-3 custom-font-family"
                key={field}
              >
                {field === "query" ? (
                  <textarea
                    className={`form-control py-5 pb-4 custom-font-family ${
                      formErrors[field] ? "border-danger" : ""
                    }`}
                    name={field}
                    style={{
                      fontSize: "14px",
                      border: "1px solid #ccc",
                      height: "100px",
                    }}
                    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                    onChange={handleInputChange}
                    onBlur={(e) =>
                      setFormErrors({
                        ...formErrors,
                        [field]: validateField(field, e.target.value),
                      })
                    }
                  />
                ) : (
                  <input
                    type={
                      field === "contact"
                        ? "number"
                        : field === "email"
                        ? "email"
                        : "text"
                    }
                    className={`form-control py-5 pb-4 custom-font-family ${
                      formErrors[field] ? "border-danger" : ""
                    }`}
                    name={field}
                    style={{ fontSize: "14px", border: "1px solid #ccc" }}
                    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                    onChange={handleInputChange}
                    onBlur={(e) =>
                      setFormErrors({
                        ...formErrors,
                        [field]: validateField(field, e.target.value),
                      })
                    }
                  />
                )}
                <label className="fs-5 custom-font-family">
                  <i
                    className={`icon-${
                      field === "query"
                        ? "81"
                        : field === "contact"
                        ? "phone"
                        : field === "email"
                        ? "envelope"
                        : "6"
                    }`}
                  ></i>{" "}
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </label>
                {formErrors[field] && (
                  <small className="text-danger fs-5">
                    {formErrors[field]}
                  </small>
                )}
              </div>
            ))}

            <div className="col-12 text-center">
              <button
                className="rn-btn edu-btn btn-medium submit-btn custom-font-family"
                id={trackingid}
                data-button-id={`Click on the button Submit on the ${pagename} Page`}
                onClick={handleButtonClick}
                type="submit"
              >
                Submit <i className="icon-4"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CustomEnquery;
