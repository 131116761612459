import React, { Suspense, useEffect } from "react"; 
import Testimonial from "../../PageSection/TestimonialSection/TestimonialSection"; 
import SEO from "../../SeoWidget/SEO/SEO";
import BranchHomeComponent from "../../PageCommonSection/CommonPageBranchSection/CommonPageBranchSection";
import { HeroProvider } from "../../../Context/Heroapi";
import { AboutProvider } from "../../../Context/Aboutapi";
import { useHomeContext } from "../../../Context/Homeapi";
import { Link } from "react-router-dom";
import { useScrollingManagementContext } from "../../../Context/ScrollingEventManagement";
import { useSaleModalContext } from "../../../Context/SaleModalmanagement";
import { useWebdetailsContext } from "../../../Context/WebDetails";
import UseAnalytics from "../../GoogleAnalytics/UseAnalytics";
import { useTrackingManagementContext } from "../../../Context/TrackingManagement";
import { useQueryContext } from "../../../Context/QueryManagement";

// Lazy-loaded components for better performance
const HeroCard = React.lazy(() => import("../../PageSection/CustomBannerCardSection/CustomBannerCardSection"));
const Highlights = React.lazy(() => import("../../PageSection/HighlightsSection/HighlightsSection"));
const About = React.lazy(() => import("../../PageSection/AboutSection/AboutSection"));
const CourseHomeComponent = React.lazy(() => import("../../PageCommonSection/CommonPageCourseSection/CommonPageCourseSection"));

function HomePage() {
  // Context hooks for managing various app states
  const { Webdetails, fetchWebdetailsByUrlHandler } = useWebdetailsContext();
  const { handleUrlChange } = useTrackingManagementContext();
  const { Homecontent, fetchHomeContentHandler } = useHomeContext();
  const { trackingid } = useTrackingManagementContext();
  const { ScrollingopenHandler } = useScrollingManagementContext();
  const { handleSaleOpen } = useSaleModalContext();
  const { getPagename } = useQueryContext(); 

  // Fetch data for web details and home content on component mount
  useEffect(() => {
    fetchWebdetailsByUrlHandler();
    fetchHomeContentHandler();
  }, []);

  // Set the page name for tracking
  useEffect(() => {
    getPagename("Home");
  }, []);

  // Extracting content and settings from the home content context
  const hidedisplay = Webdetails?.webnotification || "";
  const showcoursevalue = Homecontent.showcourseindex;
  const showaboutvalue = Homecontent.showaboutindex;
  const hide1 = Homecontent.hide1;
  const hide2 = Homecontent.hide2;
  const hide3 = Homecontent.hide3;
  const hide4 = Homecontent.hide4;
  const metatitle = Homecontent?.metatitle || "";
  const metadescription = Homecontent?.metadescription || "";

  // Open the sale modal if the web notification is enabled
  useEffect(() => {
    setTimeout(() => {
      if (hidedisplay == 1) {
        handleSaleOpen();
      }
    }, 2000);
  }, [hidedisplay]);

  // Handle URL change after a delay
  useEffect(() => {
    setTimeout(() => {
      handleUrlChange();
    }, 2000);
  }, []);

  return (
    <>
      {/* Google Analytics Integration */}
      <UseAnalytics />

      {/* SEO Configuration */}
        <SEO seot1={metatitle} seot2={metadescription} />

      {/* Hero Section */}
      <HeroProvider>
        <Suspense fallback={<p>Loading..</p>}>
          <HeroCard />
        </Suspense>
      </HeroProvider>

      {/* Highlights Section */}
      <Suspense fallback={<p>Loading..</p>}>
        <Highlights />
      </Suspense>

      {/* Branch Component */}
      <BranchHomeComponent />

      {/* About Section (conditionally rendered) */}
      {showaboutvalue === 1 && (
        <AboutProvider>
          <Suspense fallback={<p>Loading..</p>}>
            <About />
          </Suspense>
        </AboutProvider>
      )}

      {/* Courses Section (conditionally rendered) */}
      {showcoursevalue === 1 && (
        <div
          className="edu-course-area course-area-1 gap-tb-text"
          style={{ background: "#F2F4F7", padding: "30px 0px 45px" }}
        >
          <div className="container">
            <Suspense fallback={<p>Loading..</p>}>
              <CourseHomeComponent />
            </Suspense>
          </div>
          <div className="container">
            <div className="load-more-btn">
              <Link
                to="/course"
                onClick={() => ScrollingopenHandler()}
                id={trackingid}
                data-button-id={
                  "Click on the button View More Courses on the Home Page"
                }
                className="edu-btn text-white"
              >
                View More Courses <i className="icon-35"></i>
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* Testimonials */}
      <Testimonial />

      {/* Dynamic Sections based on content visibility */}
      {[hide1, hide2, hide3, hide4].map((hide, index) => (
        hide === 0 && (
          <div
            key={index}
            className="edu-course-area course-area-1 gap-tb-text py-0 custom-font-family"
          >
            {Homecontent[`bgcolor${index + 1}`] === 1 && (
              <div
                style={{
                  width: "100%",
                  height: "20px",
                  background:
                    "linear-gradient(to top right, #F2F4F7 50%, #FFFFFF 50%)",
                }}
              ></div>
            )}
            <div
              className={`col-lg-12 edu-course-area custom-font-family course-area-1 gap-tb-text bg-lighten0${Homecontent[`bgcolor${index + 1}`]}`}
              style={{ padding: "0px" }}
            >
              <div
                className="col-lg-10 mx-auto py-3"
                dangerouslySetInnerHTML={{ __html: Homecontent[`content${index + 1}`] }}
              />
            </div>
          </div>
        )
      ))}
    </>
  );
}

export default HomePage;
